import { createSlice } from "@reduxjs/toolkit";

const directPaymentSlice = createSlice({
    name : 'directPayment' ,
    initialState : {
        docs : [] ,
        page : 1 ,
        pages : 1 , 
        docsCount : 0 , 
    } , 
    reducers : {
        setStats (state , action) {
            const { docs , docsCount , page , pages } = action.payload;
            state.docs = docs;
            state.docsCount = docsCount;
            state.page = page;
            state.pages = pages;
        } 
    }
});

export const { 
    setStats 
} = directPaymentSlice.actions;

export default directPaymentSlice.reducer;